import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`


  return (
    <Layout location={location} title={siteTitle}>
        <Seo title="Feedback" />
        <section className="feedback">
            <div>
                <h1>Feedback</h1>
                <p>Give Filipe your feedback about his website through this form!</p>
                <iframe title="Feedback about FMCorreia" src="https://docs.google.com/forms/d/e/1FAIpQLSeRceDAUhZxtDRT6tgNeHUOszpPksIeTJZjvp-VAvi3_yAnVw/viewform?embedded=true" width="100%" height="800" frameborder="0" marginheight="0" marginwidth="0">A carregar…</iframe>
            </div>
        </section>

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
